import { initContract } from '@ts-rest/core'
import getAuthProvidersContract from '../../../src/functions/enums/get-auth-providers/src/contract'
import getGendersContract from '../../../src/functions/enums/get-genders/src/contract'
import getMembershipPeriodsContract from '../../../src/functions/enums/get-membership-periods/src/contract'
import getMembershipTypesContract from '../../../src/functions/enums/get-membership-types/src/contract'
import getPurchaseTypesContract from '../../../src/functions/enums/get-purchase-types/src/contract'
import getStylesContract from '../../../src/functions/enums/get-styles/src/contract'
import getVerifyTypesContract from '../../../src/functions/enums/get-verify-types/src/contract'
import getActivePublicCouponsContract from '../../../src/functions/getters/get-active-public-coupons/src/contract'
import getCreditsUsedInCurrentBillingPeriodContract from '../../../src/functions/getters/get-credits-used-in-current-billing-period/src/contract'
import getCurrentUserContract from '../../../src/functions/getters/get-current-user/src/contract'
import getFrontendInitialDataContract from '../../../src/functions/getters/get-frontend-initial-data/src/contract'
import getImageContract from '../../../src/functions/getters/get-image/src/contract'
import getImageGenerationStatusContract from '../../../src/functions/getters/get-image-generation-status/src/contract'
import getImageUserRatingContract from '../../../src/functions/getters/get-image-user-rating/src/contract'
import getImagesContract from '../../../src/functions/getters/get-images/src/contract'
import getImagesHistoryContract from '../../../src/functions/getters/get-images-history/src/contract'
import getNonMemberCreditsLeftTodayContract from '../../../src/functions/getters/get-non-member-credits-left-today/src/contract'
import getNumSitemapsContract from '../../../src/functions/getters/get-num-sitemaps/src/contract'
import getPricesContract from '../../../src/functions/getters/get-prices/src/contract'
import getStripeSessionContract from '../../../src/functions/getters/get-stripe-session/src/contract'
import getUserContract from '../../../src/functions/getters/get-user/src/contract'
import downloadImageContract from '../../../src/functions/getters/download-image/src/contract'
import cancelMembershipContract from '../../../src/functions/actions/cancel-membership/src/contract'
import contactUsContract from '../../../src/functions/actions/contact-us/src/contract'
import createStripeSubscriptionContract from '../../../src/functions/actions/create-stripe-subscription/src/contract'
import editPasswordContract from '../../../src/functions/actions/edit-password/src/contract'
import editProfileContract from '../../../src/functions/actions/edit-profile/src/contract'
import forgotPasswordContract from '../../../src/functions/actions/forgot-password/src/contract'
import isCouponCodeValidContract from '../../../src/functions/actions/is-coupon-code-valid/src/contract'
import isResetPasswordCodeValidContract from '../../../src/functions/actions/is-reset-password-code-valid/src/contract'
import rateImageContract from '../../../src/functions/actions/rate-image/src/contract'
import registerUserContract from '../../../src/functions/actions/register-user/src/contract'
import reportImageContract from '../../../src/functions/actions/report-image/src/contract'
import resetPasswordContract from '../../../src/functions/actions/reset-password/src/contract'
import shareImageContract from '../../../src/functions/actions/share-image/src/contract'
import runpodWebhookContract from '../../../src/functions/webhooks/runpod-webhook/src/contract'
import stripeWebhookContract from '../../../src/functions/webhooks/stripe-webhook/src/contract'
import verifyEmailContract from '../../../src/functions/actions/verify-email/src/contract'
import generateImageContract from '../../../src/functions/actions/generate-image/src/contract'
import cancelGenerateImageContract from '../../../src/functions/actions/cancel-generate-image/src/contract'
import enhancePromptContract from '../../../src/functions/actions/enhance-prompt/src/contract'
import deleteUserContract from '../../../src/functions/actions/delete-user/src/contract'
import generateApiKeyContract from '../../../src/functions/actions/generate-api-key/src/contract'
import deleteApiKeyContract from '../../../src/functions/actions/delete-api-key/src/contract'
import emailListSignupContract from '../../../src/functions/actions/email-list-signup/src/contract'
import resendVerificationEmailContract from '../../../src/functions/actions/resend-verification-email/src/contract'
import setImageVisibilityContract from '../../../src/functions/actions/set-image-visibility/src/contract'
import statusContract from '../../../src/functions/utils/status/src/contract'

const c = initContract()

export const contract = c.router({
  getAuthProviders: getAuthProvidersContract.getAuthProviders,
  getGenders: getGendersContract.getGenders,
  getMembershipPeriods: getMembershipPeriodsContract.getMembershipPeriods,
  getMembershipTypes: getMembershipTypesContract.getMembershipTypes,
  getPurchaseTypes: getPurchaseTypesContract.getPurchaseTypes,
  getStyles: getStylesContract.getStyles,
  getVerifyTypes: getVerifyTypesContract.getVerifyTypes,
  getActivePublicCoupons: getActivePublicCouponsContract.getActivePublicCoupons,
  getCreditsUsedInCurrentBillingPeriod: getCreditsUsedInCurrentBillingPeriodContract.getCreditsUsedInCurrentBillingPeriod,
  getCurrentUser: getCurrentUserContract.getCurrentUser,
  getFrontendInitialData: getFrontendInitialDataContract.getFrontendInitialData,
  getImage: getImageContract.getImage,
  getImageGenerationStatus: getImageGenerationStatusContract.getImageGenerationStatus,
  getImageUserRating: getImageUserRatingContract.getImageUserRating,
  getImages: getImagesContract.getImages,
  getImagesHistory: getImagesHistoryContract.getImagesHistory,
  getNonMemberCreditsLeftToday: getNonMemberCreditsLeftTodayContract.getNonMemberCreditsLeftToday,
  getNumSitemaps: getNumSitemapsContract.getNumSitemaps,
  getPrices: getPricesContract.getPrices,
  getStripeSession: getStripeSessionContract.getStripeSession,
  getUser: getUserContract.getUser,
  downloadImage: downloadImageContract.downloadImage,
  cancelMembership: cancelMembershipContract.cancelMembership,
  contactUs: contactUsContract.contactUs,
  createStripeSubscription: createStripeSubscriptionContract.createStripeSubscription,
  editPassword: editPasswordContract.editPassword,
  editProfile: editProfileContract.editProfile,
  forgotPassword: forgotPasswordContract.forgotPassword,
  isCouponCodeValid: isCouponCodeValidContract.isCouponCodeValid,
  isResetPasswordCodeValid: isResetPasswordCodeValidContract.isResetPasswordCodeValid,
  rateImage: rateImageContract.rateImage,
  registerUser: registerUserContract.registerUser,
  reportImage: reportImageContract.reportImage,
  resetPassword: resetPasswordContract.resetPassword,
  shareImage: shareImageContract.shareImage,
  runpodWebhook: runpodWebhookContract.runpodWebhook,
  stripeWebhook: stripeWebhookContract.stripeWebhook,
  verifyEmail: verifyEmailContract.verifyEmail,
  generateImage: generateImageContract.generateImage,
  cancelGenerateImage: cancelGenerateImageContract.cancelGenerateImage,
  enhancePrompt: enhancePromptContract.enhancePrompt,
  deleteUser: deleteUserContract.deleteUser,
  generateApiKey: generateApiKeyContract.generateApiKey,
  deleteApiKey: deleteApiKeyContract.deleteApiKey,
  emailListSignup: emailListSignupContract.emailListSignup,
  resendVerificationEmail: resendVerificationEmailContract.resendVerificationEmail,
  setImageVisibility: setImageVisibilityContract.setImageVisibility,
  status: statusContract.status,
})
