import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { initContract } from '@ts-rest/core'

extendZodWithOpenApi(z)

const c = initContract()

export default c.router(
  {
    getFrontendInitialData: {
      metadata: {
        openApiTags: ['Getters'],
      },
      summary: '/ get-frontend-initial-data',
      description: "Gets the data necessary for the frontend's initial load",
      method: 'GET',
      path: '/get-frontend-initial-data',
      responses: {
        200: z
          .object({
            success: z.literal<boolean>(true).openapi({
              description: 'Whether the request was successful or not.',
            }),
            data: z.object({
              authProviders: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              coupons: z.array(
                z.object({
                  id: z.string().uuid(),
                  stripe_coupon_id: z.string(),
                  stripe_promo_id: z.string().nullable(),
                  name: z.string(),
                  promotion_code: z.string().nullable(),
                  is_active: z.boolean(),
                  is_public: z.boolean(),
                  amount_off: z.number().nullable(),
                  percent_off: z.coerce.number().nullable(),
                  currency: z.string().nullable(),
                  duration: z.string(),
                  duration_in_months: z.number().nullable(),
                  max_redemptions: z.number().nullable(),
                  expires_at: z.string().datetime().nullable(),
                  times_redeemed: z.number(),
                  first_time_transaction_only: z.boolean(),
                  minimum_purchase_price: z.number().nullable(),
                  minimum_purchase_price_currency: z.string().nullable(),
                  updated_at: z.string().datetime(),
                  created_at: z.string().datetime(),
                }),
              ),
              genders: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              imageGuideTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              membershipPeriods: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              membershipTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              orientationTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              prices: z.array(
                z.object({
                  id: z.string().uuid(),
                  membership_type: z.string().nullable(),
                  membership_period: z.string().nullable(),
                  purchase_type: z.string(),
                  description: z.string(),
                  tag: z.string().nullable(),
                  num_credits: z.number(),
                  price: z.number(),
                  currency: z.string(),
                  generate_small_images: z.boolean(),
                  generate_medium_images: z.boolean(),
                  generate_large_images: z.boolean(),
                  private_images: z.boolean(),
                  generate_svgs: z.boolean(),
                  api_access: z.boolean(),
                  stripe_prod_buy_url: z.string(),
                  stripe_prod_product_id: z.string(),
                  stripe_prod_price_id: z.string(),
                  stripe_test_buy_url: z.string(),
                  stripe_test_product_id: z.string(),
                  stripe_test_price_id: z.string(),
                  upsell_price_id: z.string().nullable(),
                  upsell_text: z.string().nullable(),
                  updated_at: z.string().datetime(),
                  created_at: z.string().datetime(),
                }),
              ),
              purchaseTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              sizes: z.array(
                z.object({
                  code: z.string(),
                  type: z.string(),
                  width: z.number(),
                  height: z.number(),
                  aspect_ratio: z.string(),
                  orientation_type: z.string(),
                  minimum_subscription: z.string().nullable(),
                  updated_at: z.string().datetime(),
                  created_at: z.string().datetime(),
                }),
              ),
              sizeTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              styles: z.array(
                z.object({
                  code: z.string(),
                  pixels_example_image: z.string(),
                  svg_example_image_as_webp: z.string(),
                  svg_example_image: z.string(),
                  sort_order: z.number(),
                }),
              ),
              styleTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
              verifyTypes: z.array(
                z.object({
                  code: z.string(),
                }),
              ),
            }),
          })
          .openapi({
            title: 'Get Frontend Initial Data',
            description: 'Get all the data necessary to load the frontend initially',
          }),
        500: z.object({
          success: z.literal<boolean>(false).openapi({
            description: 'Whether the request was successful or not.',
          }),
          message: z.string().openapi({
            description: 'The error message.',
          }),
        }),
      },
    },
  },
  {
    strictStatusCodes: true,
  },
)
