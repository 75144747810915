import { initTsrReactQuery } from '@ts-rest/react-query/v5'
import { contract } from '../../../../../lib/backend/lib'

if (!process.env['NEXT_PUBLIC_PICTRIX_BACKEND_URL']) {
  throw new Error('Environment variable NEXT_PUBLIC_PICTRIX_BACKEND_URL is missing or empty')
}

export const clientApiService = initTsrReactQuery(contract, {
  baseUrl: process.env['NEXT_PUBLIC_PICTRIX_BACKEND_URL'],
  baseHeaders: {
    'Content-Type': 'application/json',
  },
  jsonQuery: true,
})
